var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"column-top"},[(!_vm.isWebview)?_c('common-header',{attrs:{"type":"allow"},on:{"click":function($event){return _vm.$router.back()}}},[_c('h1',{staticClass:"common-header-title__heading"},[_vm._v("頭痛ーるコラム")])]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading)?_c('redraw-progress',{attrs:{"is-fixed":true}}):_vm._e()],1),_c('div',{staticClass:"column-top-container",attrs:{"id":"js_column-top_container"}},[_c('column-category-selector',{attrs:{"columnCategoryList":_vm.columnCategoryList},on:{"categoryId":function($event){return _vm.setCategoryId($event)}}}),_c('section',{staticClass:"column-top-contents"},[(_vm.columnCategoryDisplay)?_c('div',{staticClass:"column-top-main__description"},[_c('span',{staticClass:"column-top-main__description-name"},[_vm._v(_vm._s(_vm.columnCategoryName))]),_c('p',{staticClass:"column-top-main__description-text"},[_vm._v(_vm._s(_vm.columnCategoryText))])]):_vm._e(),_c('ul',{staticClass:"column-top-main__list"},_vm._l((_vm.columnList),function(columnItem){return _c('li',{key:("columnItem-" + (columnItem.id)),class:{
						'column-top-main__wide-item': columnItem.wideItem,
						'column-top-main__item': columnItem.normalItem,
						'is-active': columnItem.isActive
					}},[_c('span',{class:{
							'column-top-main__wide-anchor': columnItem.wideItem,
							'column-top-main__anchor': columnItem.normalItem
						},on:{"click":function($event){return _vm.$router.push({ name: 'Cpsite', query: { url: (_vm.backendUrl + "?_path=columnDetail"), _detailId: columnItem.id } })},"touchstart":function($event){columnItem.isActive = true},"touchend":function($event){columnItem.isActive = false}}},[_c('figure',{class:{'column-top-main__wide-thumbnail': columnItem.wideItem, 'column-top-main__thumbnail': columnItem.normalItem}},[(columnItem.source_url)?_c('img',{class:{ 'is-ratio-height': columnItem.ratio },attrs:{"src":("" + (columnItem.source_url)),"alt":("" + (columnItem.alt_text))}}):_c('img',{attrs:{"src":require("@/assets/img/column_noimage.jpg")}})]),_c('div',{class:{'column-top-main__wide-contents': columnItem.wideItem, 'column-top-main__contents': columnItem.normalItem}},[_c('span',{class:("" + (columnItem.slug))},[_vm._v(_vm._s(columnItem.category))]),_c('p',[_vm._v(_vm._s(columnItem.title))])])])])}),0)]),_c('column-lower-category-list',{attrs:{"columnCategoryList":_vm.columnCategoryList},on:{"categoryId":function($event){return _vm.setCategoryId($event)}}}),(_vm.isAndroid && !_vm.isWebview)?_c('div',{staticClass:"column-top__back-button--wrapper"},[_c('p',{staticClass:"column-top__back-button",on:{"click":function($event){return _vm.$router.push({ name: 'Cpsite', query: { url: (_vm.backendUrl + "?_path=appDownload") } })}}},[_vm._v("TOPにもどる")])]):(!_vm.isAndroid)?_c('div',{staticClass:"column-top__back-button--wrapper"},[_c('p',{staticClass:"column-top__back-button",on:{"click":function($event){return _vm.$router.push({ name: 'Cpsite', query: { url: (_vm.backendUrl + "?_path=weatherGraph") } })}}},[_vm._v("気圧グラフにもどる")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }