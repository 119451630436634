<template>
<section class="column-top">
	<common-header
		type="allow"
		@click="$router.back()"
		v-if="!isWebview"
	>
		<h1 class="common-header-title__heading">頭痛ーるコラム</h1>
	</common-header>

	<!-- ローディング -->
	<transition name="fade">
		<redraw-progress v-if="isLoading" :is-fixed="true" />
	</transition>
	<!-- /ローディング -->

	<div class="column-top-container" id="js_column-top_container">
		<column-category-selector :columnCategoryList="columnCategoryList" @categoryId="setCategoryId($event)" />

		<section class="column-top-contents">
			<div v-if="columnCategoryDisplay" class="column-top-main__description">
				<span class="column-top-main__description-name">{{ columnCategoryName }}</span>
				<p class="column-top-main__description-text">{{ columnCategoryText }}</p>
			</div>

			<ul class="column-top-main__list">
				<!-- NOTE: クラスバインディングについて、三項演算子の記述にするか要検討 -->
				<li
					v-for="columnItem in columnList"
					:class="{
						'column-top-main__wide-item': columnItem.wideItem,
						'column-top-main__item': columnItem.normalItem,
						'is-active': columnItem.isActive
					}"
					:key="`columnItem-${columnItem.id}`"
				>
					<span
						@click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: columnItem.id } })"
						:class="{
							'column-top-main__wide-anchor': columnItem.wideItem,
							'column-top-main__anchor': columnItem.normalItem
						}"
						@touchstart="columnItem.isActive = true"
						@touchend="columnItem.isActive = false"
					>
						<figure :class="{'column-top-main__wide-thumbnail': columnItem.wideItem, 'column-top-main__thumbnail': columnItem.normalItem}">
							<img v-if="columnItem.source_url" :src="`${columnItem.source_url}`" :alt="`${columnItem.alt_text}`" :class="{ 'is-ratio-height': columnItem.ratio }" />
							<img v-else src="@/assets/img/column_noimage.jpg" />
						</figure>
						<div :class="{'column-top-main__wide-contents': columnItem.wideItem, 'column-top-main__contents': columnItem.normalItem}">
							<span :class="`${columnItem.slug}`">{{ columnItem.category }}</span>
							<p>{{ columnItem.title }}</p>
						</div>
					</span>
				</li>
			</ul>
		</section>

		<column-lower-category-list :columnCategoryList="columnCategoryList" @categoryId="setCategoryId($event)" />

		<!-- Android TOPへのリンク -->
		<div class="column-top__back-button--wrapper" v-if="isAndroid && !isWebview">
			<p class="column-top__back-button" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload` } })">TOPにもどる</p>
		</div>
		<!-- iOS TOPへのリンク -->
		<div class="column-top__back-button--wrapper" v-else-if="!isAndroid">
			<p class="column-top__back-button" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=weatherGraph` } })">気圧グラフにもどる</p>
		</div>
	</div>
</section>
</template>

<script>
// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'
import RedrawProgress from '../components/Molecules/RedrawProgress'
import ColumnCategorySelector from '../components/Organisms/ColumnCategorySelector'
import ColumnLowerCategoryList from '../components/Organisms/ColumnLowerCategoryList'

// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_COLUMN_LIST, SET_COLUMN_CATEGORY } from '../store/modules/column/mutation-types'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'ColumnTop',
	props: {
		categoryId: {
			type: Number,
			default: null
		}
	},
	components: {
		CommonHeader,
		RedrawProgress,
		ColumnLowerCategoryList,
		ColumnCategorySelector
	},
	data () {
		return {
			columnList: null,
			columnCategoryId: null,
			columnCategoryName: null,
			columnCategoryText: null,
			columnCategoryDisplay: false,
			columnCategoryList: null,
			pageNumber: 1,
			backendUrl: cmnConst.BACKEND_URL,
			isLoading: false
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('column', ['columnListData', 'columnCategoryData']),
		...mapGetters('common', ['isWebview', 'isAndroid'])
	},
	mounted () {
		// webview時の処理
		if (this.isWebview) this.backendUrl = 'webview'

		this.columnCategoryId = this.categoryId
		this.getColumnTopData()
	},
	beforeDestroy () {
		// スゴ得の場合、コンテンツの高さをautoでemitする。
		// CHANGE: v-ons-page廃止によりコメントアウト
		// if (!this.isWebview) this.$emit('containerHeight', 'auto')
	},
	methods: {
		// map Vuex actions
		...mapActions('column', [SET_COLUMN_LIST, SET_COLUMN_CATEGORY]),

		/**
		 * コラム一覧・カテゴリ一覧の取得処理
		 */
		getColumnTopData () {
			const SET_COLUMN_LIST = this.SET_COLUMN_LIST({
				// NOTE: ひとまず100件表示でページング実装時に変更
				per_page: 100,
				page: this.pageNumber,
				categories: this.columnCategoryId ? this.columnCategoryId : ''
			})
			const SET_COLUMN_CATEGORY = this.SET_COLUMN_CATEGORY()
			const apiArray = [ SET_COLUMN_LIST, SET_COLUMN_CATEGORY ]
			this.isLoading = true
			Promise.all(apiArray).then(res => {
				this.columnCategoryList = this.columnCategoryData.map((item, index) => {
					return {
						'id': item.id,
						'name': item.name,
						'count': item.count
					}
				}).filter(item => {
					return item.count !== 0
				})
				// CHANGED: 不要なthenなのでコメントアウト
				// }).then(res => {
				// NOTE: ratioの height*3 > width*2 は元画像の横幅が短い画像を表示枠いっぱいに表示する処理
				this.columnList = this.columnListData.map((item, index) => {
					return {
						'id': item.id,
						'title': item.title.rendered,
						'category': item['_embedded']['wp:term'] ? item['_embedded']['wp:term'][0][0]['name'] : null,
						'slug': item['_embedded']['wp:term'] ? item['_embedded']['wp:term'][0][0]['slug'] : null,
						'source_url': item['_embedded']['wp:featuredmedia'] ? item['_embedded']['wp:featuredmedia'][0]['source_url'] : null,
						'alt_text': item['_embedded']['wp:featuredmedia'] ? item['_embedded']['wp:featuredmedia'][0]['alt_text'] : null,
						'ratio': item['_embedded']['wp:featuredmedia'] && Number(item['_embedded']['wp:featuredmedia'][0]['media_details']['height']) * 3 > Number(item['_embedded']['wp:featuredmedia'][0]['media_details']['width']) * 2,
						'wideItem': index === 0 && !this.categoryId,
						'normalItem': (index !== 0 && !this.categoryId) || !!this.categoryId,
						'isActive': false
					}
				})

				this.setColumnCategoryDescription(this.columnCategoryId)
			}).catch(error => {
				// TODO: エラー処理
				console.log(error)
				this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=error`, type: 'columnTop' } })
			}).finally(() => {
				this.isLoading = false
			})
		},

		/**
		 * ファーストビューのカテゴリ概要欄追加
		 */
		setColumnCategoryDescription ($value) {
			if ($value) {
				const columnCategorySlug = this.columnList[0].slug
				this.columnCategoryName = this.columnList[0].category
				this.columnCategoryDisplay = true

				switch (columnCategorySlug) {
					case 'life_style':
						this.columnCategoryText = '毎日の暮らしに役立つライフスタイル情報です。ここちよく暮らすヒントや、生活がちょっぴり楽しくなるコツなどをお届けいたします。'
						break
					case 'tenki_fusegu':
						this.columnCategoryText = '頭痛やめまいが天気に影響していると言われても…。いえいえあきらめないでください。実は天気痛を予防する方法があるんです。'
						break
					case 'tenki_kiso':
						this.columnCategoryText = '天気痛とは天気が悪くなると頭痛やめまい、関節痛など持病や古傷が痛むことを言います。台風が近づくとなんだか頭が重いような…。それは思い込みではないかも知れません。'
						break
					case 'tenki_kisyou':
						this.columnCategoryText = '気圧って何のこと？低気圧と高気圧の違い、爆弾低気圧や熱帯低気圧、台風とは？それぞれの違いや定義を簡単に分かりやすく解説しています。'
						break
					case 'zutu_taisyo':
						this.columnCategoryText = 'いつも突然やってくる頭痛にはいくつか種類があります。実は頭痛の種類によって対処や予防法が違ってくるんです。ここで頭痛に関する正しい知識を深めていきましょう。'
						break
					default:
						this.columnCategoryDisplay = false
						break
				}
			} else {
				this.columnCategoryDisplay = false
			}
			// this.contentHeight()
		},

		/**
		 * 記事一覧表示のカテゴリID取得時の処理
		 */
		setCategoryId ($event) {
			if (this.categoryId !== $event) {
				this.columnCategoryId = $event
				this.getColumnTopData()
				this.columnCategoryId ? this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=columnTop`, _categoryId: this.columnCategoryId } }) : this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=columnTop` } })
			}
		},

		// コンテンツの高さをemitする。
		// CHANGE: v-ons-page廃止により未使用メソッド。残しておくが安定的に問題なければ削除する。
		contentHeight () {
			this.$nextTick(() => {
				const domContainer = document.getElementById('js_column-top_container')
				// TODO: androidは表示が崩れる端末がある（ドコモ指摘）ため、念のため一旦「setTimeout」で対応。iOSは「setTimeout」とスクロールユーザーアクションのタイミングで表示が崩れる。「setTimeout」せずリストのmin-heightを設定することで回避。（今後要見直し）
				if (this.isAndroid) {
					setTimeout(() => {
						if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
					}, 1000)
				} else {
					if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: コラムトップページ限定のカラー（デザインシステム外のため他での利用禁止）
$life-style-background: #F6B552;
$tenki-fusegu-background: #526FB9;
$tenki-kiso-background: #0FA298;
$tenki-kisyou-background: #F36255;
$zutu-taisyo-background: #73B952;

.column-top {
	&-container {
		overflow: hidden;
		position: relative;
		background: $background-primary;
	}
	&-main {
		margin-top: $spacing-20;
		&__description-name {
			box-sizing: border-box;
			display: block;
			color: $text-primary;
			font-size: $font-size-18;
			font-weight: bold;
			background: url(~@/assets/img/cmn_fukurouhiroshi_img01.svg) no-repeat 14px 50%, url(~@/assets/img/cmn_heading_bg01.png) repeat 0 0;
			background-size: 48px auto, 847px auto;
			height: 42px;
			line-height: 42px;
			padding-left: 4.2rem; // NOTE: 68px
		}
		&__description-text {
			box-sizing: border-box;
			color: $text-primary;
			font-size: $font-size-14;
			background: $background-secondary;
			width: calc(100% - #{$spacing-20});
			margin: $spacing-10 auto 0;
			padding: $spacing-10;
			border-radius: 4px;
		}
		&__list {
			margin: 0;
			padding: 0;
		}
		&__item {
			padding: $spacing-10;
			min-height: 121px;
			box-sizing: border-box;
			border-bottom: 1px solid $line-primary;
			&.is-active { background-color: rgba($_black, 0.1); }
		}
		&__anchor {
			display: flex;
			justify-content: space-between;
			width: 100%;
			text-decoration: none;
		}
		&__thumbnail {
			overflow: hidden;
			position: relative;
			width: 150px;
			height: 100px;
			margin: 0;
			& img {
				position: absolute;
				top: 50%;
				left: 50%;
				width: auto;
				height: 100%;
				transform: translateY(-50%) translateX(-50%);
				&.is-ratio-height {
					width: 100%;
					height: auto;
				}
			}
		}
		&__contents {
			width: calc(100% - 166px); // NOTE: 166 => 画像の幅 + 画像右の余白
			margin-left: $spacing-16;
			& > span {
				display: block;
				color: $text-seconday;
				font-size: $font-size-12;
				font-family: $family-primary;
				background: $button-primary;
				width: 120px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				&.life_style {
					background: $life-style-background;
				}
				&.tenki_fusegu {
					background: $tenki-fusegu-background;
				}
				&.tenki_kiso {
					background: $tenki-kiso-background;
				}
				&.tenki_kisyou {
					background: $tenki-kisyou-background;
				}
				&.zutu_taisyo {
					background: $zutu-taisyo-background;
				}
			}
			& > p {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
				color: $text-primary;
				font-size: $font-size-14;
				font-family: $family-primary;
				margin: $spacing-4 0 0;
			}
		}
		&__wide-item {
			overflow: hidden;
			min-height: 145px;
			box-sizing: border-box;
			border-bottom: 1px solid $line-primary;
		}
		&__wide-anchor {
			display: flex;
			align-items: flex-end;
			position: relative;
			text-decoration: none;
			&::before {
				content: '';
				display: block;
				padding-top: 38.4%;
			}
		}
		&__wide-thumbnail {
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 38.4vw;
			margin: 0;
			& img {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: auto;
				transform: translateY(-50%) translateX(-50%);
			}
		}
		&__wide-contents {
			background: rgba($background-primary, .5);
			width: 100%;
			position: absolute;
			& > span {
				display: block;
				color: $text-seconday;
				font-size: $font-size-12;
				font-family: $family-primary;
				background: $button-primary;
				width: 120px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				&.life_style {
					background: $life-style-background;
				}
				&.tenki_kiso {
					background: $tenki-kiso-background;
				}
				&.tenki_kisyou {
					background: $tenki-kisyou-background;
				}
				&.zutu_taisyo {
					background: $zutu-taisyo-background;
				}
			}
			& > p {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				color: $text-primary;
				font-size: $font-size-14;
				font-family: $family-primary;
				margin: 0;
				padding: $spacing-4;
			}
		}
	}

	&__back-button {
		width: 53.3334%;	// 200px
		margin: 0 auto;
		padding: $spacing-16 $spacing-20;
		box-sizing: border-box;
		border: solid 1px $primary;
		font-size: $font-size-14;
		text-align: center;
		line-height: $line-height-1;
		background: $primary;
		color: $text-seconday;

		&--wrapper {
			padding-top: $spacing-16;
			box-sizing: border-box;
		}
	}
}
</style>
