<template>
	<div class="column-category">
		<div class="column-category__title">コラムのカテゴリ</div>
		<ul class="column-category__list">
			<li class="column-category__list--item" v-for="(item, index) in computedColumnCategoryList" :key="index" @click="categoryIdSet(item.id)">
				<span class="column-category__list--item-name">{{ item.name }}</span>
				<v-ons-icon class="column-category__list--item-icon" icon="md-chevron-right" />
			</li>
			<li class="column-category__list--item" v-if="computedColumnCategoryList && computedColumnCategoryList.length % 2 === 1"></li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'LowerCategoryList',
	data () {
		return {
		}
	},
	props: {
		columnCategoryList: {
			type: Array,
			default: null
		}
	},
	mounted () {
	},
	computed: {
		computedColumnCategoryList () {
			return this.columnCategoryList
		}
	},
	methods: {
		// カテゴリIDを取得
		categoryIdSet (e) {
			this.$emit('categoryId', e)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";

.column-category {
	width: 100%;
	&__title {
		background: $column-category-background;
		color: $primary;
		font-family: $family-primary;
		border-top: 1px solid $column-category-border;
		border-bottom: 1px solid $column-category-border;
		padding: $spacing-10;
	}
	&__list {
		display: flex;
		list-style: none;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0;
		padding: 0;
		border-bottom: 1px solid $line-primary;
		&--item {
			position: relative;
			width: 50%;
			padding: $spacing-8 $spacing-6;
			box-sizing: border-box;
			border-bottom: 1px solid $line-primary;
			&:nth-child(odd) {
				border-right: 1px solid $line-primary;
			}
			&:nth-last-child(-n+2) {
				border-bottom: none
			}
			&-name {
				color: $text-primary;
				font-size: $font-size-12;
				font-family: $family-primary;
			}
			&-icon {
				position: absolute;
				color: $button-primary;
				font-size: $font-size-20;
				top: 50%;
				right: $spacing-6;
				transform: translateY(-50%) translateX(-50%);
			}
		}
	}
}
</style>
