<template>
	<div class="column-category-selector__wrapper">
		<div class="column-category-selector__accordionbg" @click="clickAccordionToggle" :class="{ 'is-toggle': accordionToggle }"></div>
		<div class="column-category-selector">
			<div class="column-category-selector__heading" @click="clickAccordionToggle">
				<span class="column-category-selector__heading-name">コラムのカテゴリを選択</span>
				<v-ons-icon class="column-category-selector__heading-icon" :icon="accordionToggle ? 'md-chevron-up' : 'md-chevron-down'" />
			</div>
			<nav class="column-category-selector__navi" :style="accordionToggle ? { height: `${categorySelectorListHeight}px`} : { height: 0 }">
				<ul class="column-category-selector__list" id="js_category_selector_list">
					<li class="column-category-selector__item" @click="categoryIdSet(null)">
						<span class="column-category-selector__item-name">全て</span>
						<v-ons-icon class="column-category-selector__item-icon" icon="md-chevron-right" />
					</li>
					<li class="column-category-selector__item" v-for="(item, index) in columnCategoryList" :key="index" @click="categoryIdSet(item.id)">
						<span class="column-category-selector__item-name">{{ item.name }}</span>
						<v-ons-icon class="column-category-selector__item-icon" icon="md-chevron-right" />
					</li>
					<li class="column-category-selector__item" v-if="columnCategoryList && columnCategoryList.length % 2 === 0"></li>
				</ul>
			</nav>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ColumnCategorySelector',
	data () {
		return {
			accordionToggle: false,
			categorySelectorListHeight: null
		}
	},
	props: {
		// カテゴリリスト
		columnCategoryList: {
			type: Array,
			default: null
		}
	},
	mounted () {
	},
	computed: {
	},
	methods: {
		// モーダルを開閉
		clickAccordionToggle () {
			// NOTE: 1 => borderの高さ
			if (!this.categorySelectorListHeight) this.categorySelectorListHeight = document.getElementById('js_category_selector_list').clientHeight + 1
			this.accordionToggle = this.accordionToggle !== true
			this.$emit('accordionToggle', this.accordionToggle)
		},

		// カテゴリIDを取得
		categoryIdSet (e) {
			this.clickAccordionToggle()
			this.$emit('categoryId', e)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variable";

.column-category-selector {
	position: relative;
	z-index: 1;
	&__heading {
		position: relative;
		font-family: $family-primary;
		background: $column-category-background;
		border-top: 1px solid $column-category-border;
		border-bottom: 1px solid $column-category-border;
		padding: $spacing-10;
	}
	&__heading-name {
		color: $primary;
	}
	&__heading-icon {
		position: absolute;
		color: $button-primary;
		top: 50%;
		right: $spacing-2;
		transform: translateY(-50%) translateX(-50%);
		font-size: $font-size-24;
	}
	&__navi {
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 0;
		transition: height .2s linear;
	}
	&__list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: $background-primary;
		border-bottom: 1px solid $column-category-border;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style: none;
	}
	&__item {
		position: relative;
		width: 50%;
		padding: $spacing-8 $spacing-6;
		box-sizing: border-box;
		border-bottom: 1px solid $line-primary;
		&:nth-child(odd) {
			border-right: 1px solid $line-primary;
		}
		&:nth-last-child(-n+2) {
			border-bottom: none
		}
	}
	&__item-name {
		color: $text-primary;
		font-size: $font-size-12;
		font-family: $family-primary;
	}
	&__item-icon {
		position: absolute;
		color: $button-primary;
		font-size: $font-size-20;
		top: 50%;
		right: $spacing-6;
		transform: translateY(-50%) translateX(-50%);
	}
	&__accordionbg {
		position: absolute;
		top: 0;
		left: 0;
		background: rgba($_black, 0);
		width: 100%;
		height: 0;
		z-index: 1;
		transition: background .4s linear;
		// pointer-events: none;
		&.is-toggle {
			background: rgba($_black, .2);
			height: 100%;
		}
	}
}
</style>
